'use client';

import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HomeIcon from '@mui/icons-material/Home';
import Link from 'next/link';

const StyledErrorIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
   fontSize: '8rem',
   color: theme.palette.error.main,
   marginBottom: theme.spacing(2),
}));

const AnimatedBox = styled(Box)(({ theme }) => ({
   animation: 'float 3s ease-in-out infinite',
   '@keyframes float': {
      '0%': {
         transform: 'translateY(0px)',
      },
      '50%': {
         transform: 'translateY(-20px)',
      },
      '100%': {
         transform: 'translateY(0px)',
      },
   },
}));

export default function NotFound() {
   return (
      <Container maxWidth="sm">
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'center',
               minHeight: '100vh',
               textAlign: 'center',
            }}
         >
            <AnimatedBox>
               <StyledErrorIcon />
            </AnimatedBox>
            <Typography variant="h2" component="h1" gutterBottom>
               404
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
               Oops! Page not found.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
               The page you&apos;re looking for doesn&apos;t exist or has been moved.
            </Typography>
            <Link href="/" passHref>
               <Button
                  variant="contained"
                  color="primary"
                  startIcon={<HomeIcon />}
                  size="large"
               >
                  Back to Home
               </Button>
            </Link>
         </Box>
      </Container>
   );
}